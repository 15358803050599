<template>
  <div>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="app-action">
        <b-row>
          <b-modal
            ref="my-modal"
            centered
            cancel-title="No"
            cancel-variant="outline-secondary"
            title-tag="div"
          >
            <validation-observer ref="simple">

              <b-form
                class="p-2"
              >

                <!-- Calendar -->

                <validation-provider

                  #default="{ errors }"
                  name="leaveType"
                  rules="required"
                >

                  <b-form-group
                    label="نوع الاجازة"
                    label-for="leaveType"
                  >
                    <v-select

                      v-model="eventLocal.leave_type_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="leaveTypeOptions"
                      label="name"
                      :reduce="val => val.id"
                      :clearable="false"
                    />

                   
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>
                <!-- Start Date -->

                <validation-provider

                  #default="{ errors }"
                  name="date"
                  rules="required"
                >

                  <b-form-group
                    label="Start Date"
                    label-for="start-date"
                  >
                    <flat-pickr
                      v-model="eventLocal.start"
                      disabled
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                    />
                  
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>

                <validation-provider

                  #default="{ errors }"
                  name="assign_to"
                  rules="required"
                >

                  <b-form-group
                    label=" الموظف"
                    label-for="leaveTypeupdate"
                  >

                    <v-select

                      v-model="eventLocal.assign_to"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="employeOptions"
                      label="label"
                      
                      :reduce="val => val.value"
                      :clearable="false"
                    />

              
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>
                <!-- Guests -->

                <!-- duration -->
                <validation-provider
                  #default="{ errors }"

                  name="tttime"
                  rules="required"
                >
                  <b-form-group

                    label="مدة الاجازة"
                    label-for="event-duration"
                  >
                    <b-form-input

                      id="event-duration"
                      v-model="eventLocal.duration"
                      type="number"
                      placeholder="مدة الاجازة"
                    />
                 
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                </validation-provider>
                <!-- Guests -->

                <!-- Guests -->
                <validation-provider

                  #default="{ errors }"

                  name="typedurelse"
                  rules="required"
                >
                  <b-form-group

                    label="نوع المدة"
                    label-for="add-duration_type"
                  >
                    <v-select

                      v-model="eventLocal.duration_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="durationTypeOptions"
                      :reduce="val => val.id"

                      label="name"
                    />
                  </b-form-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
               
                </validation-provider>
                <!-- Guests -->
                <!-- Textarea -->

                <b-form-group

                  label="تفاصيل"
                  label-for="event-body"
                >
                <b-form-textarea

                    id="event-body"   rows="1"
                    v-model="eventLocal.body"
                  />
                </b-form-group>

                <div class="d-flex mt-2">

                  <b-button

              
                    variant="primary"
                    class="mr-2"
                 @click="addEvent(eventLocal)"
                  >
                    إضافة
                  </b-button>

                </div>
              </b-form>

            </validation-observer>
             <template slot="modal-footer">
       
      </template>
          </b-modal>

          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select

              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col
            cols="6"
            class="d-flex align-items-end justify-content-end mb-1 mb-md-0"
          >
            <b-button

              variant="primary"
              class="mr-1"
              @click="saveData"
            >
              حفظ تعديلات
            </b-button>
          </b-col>

          <!-- Search -->

        </b-row>
      </div>

      <!-- Update Mail Folder Dropdown -->

      <b-table
        ref="refFamiliesListTable"
        :items="UserData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
      >
        <!-- Column: editfield -->

        <template #cell(status)="data">
          <b-form-group class="mb-0">

            <b-form-radio-group
              v-model="data.item.status"

              class="demo-inline-spacing"
            >
              <b-form-radio
                v-model="data.item.status"
                :value="0"
                @change="checkItem(data.item)"
              >
                غائب
              </b-form-radio>
              <b-form-radio
                v-model="data.item.status"
                :value="1"
              >
                حاضر
              </b-form-radio>

            </b-form-radio-group>
          </b-form-group>
        </template>
        <template
          v-if="changeStatus"
          #cell(leave_type_id)="data"
        >
          <v-select

            v-model="data.item.leave_type_id"
            :options="leaveTypeOptions"
            label="name"
            :reduce="val => val.id"
          />
        </template>
        <template
          v-if="changeStatus"
          #cell(duration)="data"
        >

          <b-form-input

            id="event-duration"
            v-model="data.item.duration"
            type="number"
            placeholder="مدة الاجازة"
          />
        </template>
        <!-- Column: Actions -->
        <!-- <template #cell(id)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'show-user-visit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>

          </b-dropdown>
        </template> -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,

  BForm,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BModal,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormInvalidFeedback,
  BPagination,
  BFormCheckbox,
} from 'bootstrap-vue'
import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'

import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import userListStoreModule from './userListStoreModule.js'
import useRequestItemList from './list'
import store from '@/store'

export default {
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const user_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(user_APP_STORE_MODULE_NAME)) { store.registerModule(user_APP_STORE_MODULE_NAME, userListStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(user_APP_STORE_MODULE_NAME)) { store.unregisterModule(user_APP_STORE_MODULE_NAME) }
    })
    // Mail Selection
    // ------------------------------------------------
    const Form = ref({
      name: '',
      number: '',
      details: '',
    })
    const onSubmit = () => {
      // console.log(Form);

      store.dispatch('app-user/Createuser', Form.value).then(response => {
        Form.value.name = ''
        Form.value.number = ''
        Form.value.details = ''
        resetForm()
        refetchData()
        Vue.swal({
          title: '',
          text: 'تمت أضافة المانح بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
    const {
      UserData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchUser,

      selectedItem,
      // Extra Filters
    } = useRequestItemList()

    return {
      UserData,

      onSubmit,
      refFormObserver,
      resetForm,
      Form,
      getValidationState,
      // Sidebar

      search: null,
      fetchUser,

      filterOn: [],
      searchTerm: '',
      tableColumns,
      selectedItem,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
    }
  },

  components: {
    BFormGroup,
    BFormTextarea,
    BForm,
    flatPickr,
    BInputGroup,
    BModal,
    BFormRadio,
    BFormRadioGroup,
    required,
    BFormInvalidFeedback,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      leaveTypeOptions: [],
      employeOptions: [],
      durationTypeOptions: [{ id: 1, name: 'ساعة' },
        { id: 0, name: 'يوم' }],
      changeStatus: false,
      eventLocal: {},
      status:1,

    }
  },
  created() {
    this.getLeaveType()
  },
  methods: {
    checkItem(event) {
 
      this.eventLocal.user_id = event.user_id.id
      this.eventLocal.start = event.date
      this.$refs['my-modal'].show()
      this.changeStatus = true
      
    },
    getLeaveType() {
      const url = '/api/v1/leave-types'

      this.$http.get(url).then(res => {
       // console.log(res.data)
      //  this.leaveTypeOptions = res.data.data.data
      })
      this.employeOptions = []
      this.$http.get('/api/v1/get-all-users').then(res => {
        res.data.forEach(el => {
          this.employeOptions.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })
        })
        // console.log(this.optionemploye);
      })
    },
    saveData() {
     
      const transformedArray = this.UserData.map(item => ({
        user_id: item.user_id.id,
        
        status: item.status,
        date: item.date,
        notes: item.notes,
      }))
      this.$http.post('/api/v1/employee-attendence/create-many-users-attendence', transformedArray).then(res => {
        // console.log(res);
        this.changeStatus = false
     
        this.$swal({
          title: 'تم  الحفظ بنجاح',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      // console.log(this.selectedItem);
    },
    addEvent(eventData) {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
            //  console.log(eventData)
              const user = JSON.parse(localStorage.getItem('userData'))

              const data = {
                status: 0,
                start: eventData.start,
                assign_to: eventData.assign_to,
                body: eventData.body,
                duration: eventData.duration,
                duration_type: eventData.duration_type,
                user_id: eventData.user_id,
                leave_type_id: eventData.leave_type_id,
              }

              this.$http.post('/api/v1/leave', data).then(res => {
                // console.log(res);
                this.$refs['my-modal'].hide()
        this.eventLocal={}
        this.$swal({
          title: 'تم  الحفظ بنجاح',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

              })
                .catch(error => {
                  Vue.swal({
                    title: `${error.response.data.message}`,
                    icon: 'error',

                    confirmButtonText: 'موافق',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            }
          })
      })
    },
  },
}
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.app-action {
  margin: 18px;
}
.d-block.invalid-feedback {
    color: red;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
